export default function getPrice(price: string, percentagem: string) {
  if (!price) {
    return null;
  }
  if (!percentagem) {
    return price;
  }

  const currency = price.split('$');
  const priceFormatted = currency[1].replace(',', '.');
  const percentagemFormatted = percentagem?.replace(',', '.');

  const diference = (
    (parseFloat(priceFormatted) * parseFloat(percentagemFormatted)) /
    100
  ).toFixed(2);

  const total = (parseFloat(priceFormatted) + parseFloat(diference)).toFixed(2);

  return `R$${total.replace('.', ',')}`;
}
