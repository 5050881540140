import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.button`
  height: max-content;
  min-height: 4.2rem;
  width: 100%;
  padding: 0 15px;

  border: 0;
  background: transparent;
  border: 1px solid ${colors.green_primary};
  border-radius: 0.5rem;
  color: ${colors.green_primary};
  box-shadow: 2px 4px 5px #bec9c5;

  transition: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 17px;

  &:hover {
    background: ${colors.green_dark};
    color: ${colors.text_white};
  }
`;
