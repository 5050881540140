/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from 'react';

import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import freeShipping from 'assets/freteGratis.png';
import emptyImage from 'assets/produto-sem-imagem.jpg';
// import ultraPremioTag from 'assets/selo-ultra-premio.png';
import ultraPremioTag from 'assets/selo-ultra-premio.png';
import { IState } from 'store';
import { addProductToCart } from 'store/modules/cart';
import { IConfigApplicationProps } from 'store/modules/config';
import { IProductCarouselProps } from 'store/modules/product';
import Button from 'components/common/Button';
import ButtonOutline from 'components/common/Button/ButtonOutline';
import getPrice from 'utils/getPrice';
import { getGACartItemsParams } from 'utils/gtag';

import * as S from './styles';

interface IProductItemProps {
  item: IProductCarouselProps;
  componenteVitrineId?: number;
}

const ProductItem: React.FC<IProductItemProps> = ({
  item,
  componenteVitrineId,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const config = useSelector<IState, IConfigApplicationProps>(
    state => state.config.config,
  );
  const percent = useSelector<IState, string>(
    state => state.config.porcentagem,
  );

  const priceFrom = useMemo(() => {
    return getPrice(item.precoDeTexto, percent);
  }, [percent, item.precoDeTexto]);

  const pricePer = useMemo(() => {
    return getPrice(item.precoPorTexto, percent);
  }, [percent, item.precoPorTexto]);

  const handleBuyProduct = useCallback(() => {
    router.push(`/${item.permalink}`);
  }, [item]);

  const handleAddProductToCart = useCallback(() => {
    dispatch(
      addProductToCart({
        sku: item.codigo,
        quantity: 1,
        showAfterRequest: true,
        GA: getGACartItemsParams({ product: item, qty: 1 }),
        componenteVitrineId,
      }),
    );
  }, [componenteVitrineId, dispatch, item]);

  const handleAddProgressiveProduct = useCallback(() => {
    dispatch(
      addProductToCart({
        sku: item.codigo,
        quantity: item.fatorKit,
        showAfterRequest: true,
        GA: getGACartItemsParams({ product: item, qty: item.fatorKit }),
        componenteVitrineId,
      }),
    );
  }, [componenteVitrineId, dispatch, item]);

  return (
    <S.Item title={item.nome} data-sku={item.sku}>
      <div>
        <Link
          href={{
            pathname: `/${item.permalink}`,
            query: { cvi: componenteVitrineId },
          }}
        >
          <a className="image-box">
            <Image
              className="image-product"
              src={item.imagemUrl}
              alt={item.nome}
              width={300}
              height={300}
              quality={60}
              priority
            />

            {item.desconto ? (
              <div className="tag-discount">
                <span className="percentage">{item.desconto}%</span> OFF
              </div>
            ) : null}

            {item.ultraPremio && (
              <div className="ultra-premio-tag">
                <Image src={ultraPremioTag} alt="Selo Ultra Prêmio" />
              </div>
            )}

            {item.ultraDesconto && config?.ultraDescontao?.estaVigente && (
              <img
                loading="lazy"
                className="selos"
                src={config?.ultraDescontao?.seloVigenteVitrineUrl}
                alt="Selo Promocional"
              />
            )}
          </a>
        </Link>

        {/* {item.possuiGenerico && (
          <Link href={`/genericos/${item.id}`}>
            <a href={`/genericos/${item.id}`} className="generics">
              <img src={genericsIcon} alt="Genéricos" />
              <img src={similarIcon} alt="Similar" />

              <span>GENÉRICOS OU SIMILARES</span>
            </a>
          </Link>
        )} */}

        {item.freteGratis && (
          <Link
            href={{
              pathname: `/${item.permalink}`,
              query: { cvi: componenteVitrineId },
            }}
          >
            <span className="image free-shipping">
              <Image src={freeShipping} alt="Frete Grátis" />
            </span>
          </Link>
        )}

        {item.aplicarPromocao && (
          <Link
            href={{
              pathname: `/${item.permalink}`,
              query: { cvi: componenteVitrineId },
            }}
          >
            <img
              src={item.promocaoImagemUrl}
              alt="Promoção"
              className="image promotion"
            />
          </Link>
        )}

        {/* {item.possuiBrinde && <img src={giftIcon} alt="Brinde" />} */}

        <Link
          href={{
            pathname: `/${item.permalink}`,
            query: { cvi: componenteVitrineId },
          }}
        >
          <a className="text">
            <p className="description">{item.nome}</p>

            <span>
              {priceFrom && <p className="price-is">{priceFrom}</p>}
              <p className="price-new">
                <span className="price-for">{pricePer}</span>
                <span className="unity">cada</span>
              </p>

              {item.numeroParcela !== 1 && item.numeroParcela && (
                <span className="parcel">
                  em até {item.numeroParcela || 1}x sem juros
                </span>
              )}

              {item.precoUnitarioItemKitTexto && (
                <span className="preco-unitario">
                  Preço unitário: {item.precoUnitarioItemKitTexto}
                </span>
              )}
            </span>
          </a>
        </Link>
      </div>

      {item.controleEstoque === 0 && item.naoPossuiRestricaoVenda ? (
        <p className="sold-out">ESGOTADO</p>
      ) : item.controleEstoque > 0 && item.naoPossuiRestricaoVenda ? (
        <div className="button-buy">
          <ButtonOutline onClick={handleBuyProduct}>COMPRAR</ButtonOutline>

          <ButtonOutline
            className="add-to-cart"
            onClick={handleAddProductToCart}
          >
            ADICIONAR AO CARRINHO
          </ButtonOutline>

          {item.aplicarPromocao && item.fatorKit > 1 && (
            <Button
              className="buy-progressive-button"
              onClick={handleAddProgressiveProduct}
            >
              COMPRAR KIT
            </Button>
          )}
        </div>
      ) : (
        <span className="sell-restriction">
          <p>
            <strong>Venda nas lojas Ultrafarma</strong>
          </p>

          <ul>
            <li>Av. Jabaquara - São Paulo</li>
          </ul>
          <p>
            <span>Clique aqui</span> para consultar o estoque
          </p>
        </span>
      )}
    </S.Item>
  );
};

export default ProductItem;
