import React, { useEffect, useState } from 'react';

import { IProductCarouselProps } from 'store/modules/product';

import ProductItem from '../ProductItem';
import { Container } from './styles';

interface IGridProps {
  items: IProductCarouselProps[];
  componenteVitrineId?: number;
}

const Grid: React.FC<IGridProps> = ({ items, componenteVitrineId }) => {
  return (
    <Container>
      {items.map(item => (
        <ProductItem
          key={`grid-${item.id}`}
          item={item}
          componenteVitrineId={componenteVitrineId}
        />
      ))}
    </Container>
  );
};

export default Grid;
