import styled from 'styled-components';

import { colors, metrics } from 'styles';

export const Item = styled.div`
  padding: 0 15px 15px;

  .image-product {
    aspect-ratio: 1/1;
    max-height: 300px;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: 0 auto 9px;
  }

  .sold-out {
    display: block;
    color: #e6421e;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }

  .selos {
    position: absolute;
    top: 0;
    left: 0;
  }

  .image-box {
    position: relative;
    display: block;
  }

  .tag-discount {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAAA+CAYAAACY9hNHAAAACXBIWXMAAAsSAAALEgHS3X78AAAEhElEQVRoge1b3XEaMRBeM/eOO7BTgbm3e+NSgXEFtiswrsBQQUgFxh2QCoyfcm8HFQQqcHjOzTgj5jsi8Ena1YmfDHwzjMfobz9pV9pdibOPjw86NjSOjvGJdCAUWXxeZPFliN5UP6q/0DIGs+kii1MiGhDRFb6aq/+jJB949NUloh4RNfHVlIi6UZKPQ8gahHSRxXdE9GwofomS/E7Q15CIbg3F91GSD/2k/IfapIssbhFR7qj2yFlxrPA3R7U4SvKJTMp1hLBpzsz3XHaO8l6g8ayoRRpqfcWo2mQQ0m3Yhqsiizt15K670pLBO6adGN9L+mLvEVXwJg1BrwVN1CqmhrKUucolruscZXVWuuXRxkZ6F+MvUYd0SEFDTqATJ9/7WHAiLcQsYJuQfTkR6RVwDJSbyiRK8t+WDnycf1ObscXf9hrfxqWhVVIH/jsRveLzrpx/k/sYJbma6TeBkAsiGhnKRijn4g3jV5G9RNCyyWXl0DRQsWOIktTsT/QGG+D4yiUGJs3B95IQtHJcyDkxaM1z6b6WK20bsIkGn4gjvu0zhJxGSW6dIJRPGX31q+JqLby1eXZLng2EhheMwZ5Rt0rY75Z2bwJHInWYzPeqyYNcpnhex4WqGwm9IWV7n2w8SvJukcUjBAJluVLZkSToh5qnWDWliqV/rex3aMmcmPaKKrTO/vxsqZl7EjQKkr0IBUfWpgr9yGPsnk8gjxxaCk3QtWWGz9gzBybZTJfwIa3s4tJ0ZOiArXWhqqYNpo2/T0UWl8eaTZVXwHHK2Y/W0PD0bFypn1aRxWPkzm4FsXIT9V9Ve2iHtxwGzBqenlUlkPMegGzbowsdbZAfBM59jxtQ03ndnqDKyjF4CCPbCg9wkLyTBhrmii/HOanCmmeFHTT3sS8mVL95hYNkiw2qsNz0VnnvIosnzMymmq2VLRVZLD3y6qKvOyhFFs+Yk628wqW26KHlHdPp1wcc7pgwYZfXj0zOkbXQM6gr0rg1SB32/VI6JtiwpOFgKNxifII8L5Z+FZ9UvxVZSyKgQKnA44bzr/zhm/JOCrYVesOS4qG0cch1s+G3T8GjtXkNJL7Lwtn5unuORnyVenIi0vCAJsLE/LaxwGqynSxpjmx4YIQJ8ohiATZpHE11vaxtoQ35WGCpN9T614ES1vGFo+bclT6Y+NkBlpxO0kimHapab6LNubvmrLT4ocye4ZTXShpn8raCiG3hwhWHu1a61o3/HmGV20W61tuOPcIqt5E0VOTQHBEumjYVt610iEzFPmGU30Y6+JvMHcMo/+lS/lhgI+19038gMMpvI13r0ekBwCi/NcoSZBoPDWsZ2024bPp/87tLWOV2xtOCfLiOOWzK64KtZvtVftsEzu7NzYcT6qn7a3WrmULFbgTXRnNkXfX298LxnfGCJHMycqz4DzWg6TEN8tS2tLF6WtE1tD1HgsD26lilfDuczIk0G1o+iyj98gVuPQfM++QWbiR04dVk9Tg/UYA/3a0YX/TMY2+/wFs+eKn5WwxfnH52eCw4PtJE9Bdkh97uSMjIwgAAAABJRU5ErkJggg==);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: -5px;
    z-index: 50;
    width: 50px;
    height: 50px;
    font-weight: 600;
    color: #333;
    padding: 25px 10px 0;
    font-size: 11px;
    line-height: 9px;
    text-align: center;
    text-wrap: wrap;
  }

  .description {
    font-weight: 700;
    color: #303030;
    font-size: 14px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .price-is {
    font-weight: 700;
    font-size: 22px;
    text-decoration: line-through;
    color: #a0a0a0;
    margin-bottom: 3px;
  }

  .price-new {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 4px;
  }

  .price-for {
    font-weight: 700;
    font-size: 22px;
    color: ${colors.green_dark};
    margin-bottom: 3px;
  }

  .unity {
    font-size: 15px;
    color: #a0a0a0;
    font-weight: 500;
  }

  .parcel {
    font-size: 14px;
    margin-bottom: 15px;
    display: flex;
    color: #024902;
  }

  .button-buy {
    opacity: 0;
    transition: 0.3s;

    .add-to-cart {
      padding: 5px;
      margin-top: 0.8rem;
      font-size: 1.3rem;
      color: ${colors.blue_light};
      border-color: ${colors.blue_light};

      &:hover {
        color: ${colors.white};
        background-color: ${colors.blue_light};
      }
    }
  }

  &:hover {
    .button-buy {
      opacity: 1;
    }
  }

  .buy-progressive-button {
    font-weight: 700;
    color: ${colors.text_black};
    background-color: ${colors.yellow_light};

    &:hover {
      opacity: 0.8;
    }
  }

  .free-shipping, .promotion {
    margin-bottom: 7px;
    width: 100%;
  }

  .preco-unitario {
    font-size: 14px;
    font-weight: 700;
    margin: 5px 0 15px;
    display: block;
  }

  .ultra-premio-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 56px;
  }

  @media only screen and (max-width: 1400px) {
    padding: 0 8px 15px;

    .description {
      font-size: 14px;
      margin-bottom: 8px;
      word-wrap: break-word;
    }

    .price-is {
      font-size: 20px;
      margin-bottom: 2px;
    }

    .price-new {
      gap: 4px;
    }

    .price-for {
      font-size: 20px;
      margin-bottom: 3px;
    }

    .unity {
      font-size: 14px;
    }

    .parcel {
      font-size: 13px;
      margin-bottom: 12px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .tag-discount {
      font-size: 10px;
      line-height: 8px;
      padding: 27px 10px 0;
    }

    .description {
      font-size: 15.5px;
      margin-bottom: 8px;
    }

    .price-is {
      font-size: 19px;
      margin-bottom: 0;
    }

    .price-new {
      gap: 4px;
    }

    .price-for {
      font-size: 19px;
      margin-bottom: 2px;
    }

    .unity {
      font-size: 13px;
    }

    .parcel {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .description {
      font-size: 15px;
      margin-bottom: 8px;
    }

    .price-is {
      font-size: 18px;
      margin-bottom: 0;
    }

    .price-new {
      gap: 4px;
    }

    .price-for {
      font-size: 18px;
    }

    .unity {
      font-size: 12px;
    }

    .parcel {
      font-size: 11.5px;
    }
  }
`;
